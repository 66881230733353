import Axios from 'axios';
import moment from 'moment';
import { baseUrl, requestHeader } from '../../config';
import { Logout, myProfileObj } from '../../utils/handler';
import store from "../store";
import { CatchReturnError } from './actionHandler';
import * as types from './types';
export const getMyProfile = async () => await Axios.get(`${baseUrl}/myProfile`, requestHeader)
    .then(async (res) => {
        const response = res.data;
        await store.dispatch({ type: types.GET_MY_PROFILE, payload: response });
        const timeStamp: any = moment();
        localStorage.setItem('lastTimeStamp', timeStamp);
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
        Logout();
    });
export const updateMyProfile = (payload: any) => Axios.patch(`${baseUrl}/users`, payload, requestHeader)
    .then((res) => {
        const response = res.data;
        getMyProfile();
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateUser = (id: number, payload: any) => Axios.patch(`${baseUrl}/users/${id}`, payload, requestHeader)
    .then((res) => {
        const response = res.data;
        if (myProfileObj().id === id) {
            getMyProfile();
        }
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const createUser = (payload: any) => Axios.post(`${baseUrl}/users`, payload, requestHeader)
    .then((res) => {
        const response = res.data;
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const getUsersAction = () => Axios.get(`${baseUrl}/users`, requestHeader)
    .then((res) => {
        const response = res.data;
        store.dispatch({ type: types.USER_LIST, payload: response });
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const deleteUserAction = (id: number) => Axios.delete(`${baseUrl}/users/${id}`, requestHeader)
    .then((res) => {
        const response = res.data;
        // to make sure current user is not deleted
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const getRolesAction = () => Axios.get(`${baseUrl}/roles`, requestHeader)
    .then((res) => {
        const response = res.data;
        store.dispatch({ type: types.USER_ROLES, payload: response });
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const createUserGroups = (payload: any) => Axios.post(`${baseUrl}/user-groups`, payload, requestHeader)
    .then((res) => {
        const response = res.data;
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const addRemoveUserToGroup = (payload: { userGroupId: number; userId: number }) => Axios.patch(`${baseUrl}/add-remove-userGroup`, payload, requestHeader)
    .then((res) => {
        const response = res.data;
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateUserGroups = (payload: any, groupId: number) => Axios.patch(`${baseUrl}/user-groups/${groupId}`, payload, requestHeader)
    .then((res) => {
        const response = res.data;
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const fetchUserGroups = () => (
    Axios.get(`${baseUrl}/user-groups`, requestHeader)
        // return Axios.get(baseUrl + '/user-groups?search', requestHeader)
        .then((res) => {
            const response = res.data;
            store.dispatch({ type: types.USER_GROUPS, payload: response });
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        })
);
export const deleteUserGroup = (groupId: number) => Axios.delete(`${baseUrl}/user-groups/${groupId}`, requestHeader)
    .then((res) => {
        const response = res.data;
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const getUserByGroupId = (groupId: number, filter: { showGroupUsers?: boolean }) => Axios.get(`${baseUrl}/getUserByGroupId/${groupId}`, { ...requestHeader, params: filter })
    .then((res) => {
        const { data } = res;
        return data;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const updatePassword = (oldPassword: string, newPassword: string, confirmNewPassword: string) => Axios.patch(
    `${baseUrl}/users/changePassword`,
    { oldPassword, password: newPassword, confirmPassword: confirmNewPassword },
    requestHeader
)
    .then((res) => {
        const { data } = res;
        return data;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
